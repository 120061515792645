import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageEnd = props => (
    <Layout {...props}>
        <Seo title='Games' />
        <Stack>
            <Main>
               <h1>More Interesting Content For You...</h1>
                <div id="rc-widget-f560ce" data-rc-widget data-widget-host="habitat" data-endpoint="//trends.revcontent.com" data-widget-id="276651"></div>
            </Main>
        </Stack>
    </Layout>
)

export default PageEnd
